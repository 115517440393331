import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  Grid,
  Modal,
  makeStyles,
} from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Form, Field } from 'react-final-form'
import ConfirmCard from '../../../components/common/Card'
import { useRouter } from '../../../../utils/helper'
import { InputField, CheckBoxField } from '../../../components/fields'
import { createValidation, required, maxLength, cp } from '../../../../utils/field-validation'
import Toast from '../../../components/common/Toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Text from '../../../components/common/Text'
import {
  useCreateArticle,
  useDeleteArticle,
  useEditArticle,
  useGetArticleById,
} from '../../../services/article/article-query'
import { ArticleBodyType } from '../../../services/article/article-type'
import { useCurrentUser } from '../../../services/user/user-query'
import { isEmpty } from 'lodash'
import mobileFrameImg from '../../../../asset/images/mobile_frame.png'
import { useVisible } from '../../../../utils/custom-hooks'
import * as paths from '../../../constants/path'
import { UploadThumbNail } from './UploadThumbNail'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { Authorize, validateCorrectRole } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import Quill from 'quill'
import BlotFormatter from 'quill-blot-formatter'
import ImageCompress from 'quill-image-compress'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import { toast } from 'react-toastify'
import { css } from '@emotion/css'

// Register Quill modules
Quill.register('modules/blotFormatter', BlotFormatter)
Quill.register('modules/imageCompress', ImageCompress)

const CustomCard = styled(ConfirmCard)`
  width: 100%;
  max-width: 1000px;
`

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

const IPhoneFrame = styled.div`
  background-image: url(${mobileFrameImg});
  background-repeat: no-repeat;
  background-size: 1080px;
  background-position-x: center;
  width: 500px;
  height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -78px;
`

const Screen = styled.div`
  width: 375px;
  height: 766px;
  margin-top: 160px;
  margin-left: 8px;
  border: 1px solid #dbdbdb;
  padding-left: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const MarginTopButton = styled.div`
  margin-top: 86px;
  margin-left: 24px;
`

const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const imgStyle = css`
  img {
    width: 100%;
    height: 100%;
  }
`

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const GridUploadArea = styled(Grid)`
  padding: 8px 16px 8px 0;
  border-radius: 4px;
  border: 1px dotted gray;
  :hover {
    background-color: #dadada;
  }
`

type FormValue = {
  title: string
  description: string
  authorName: string
  isActive: boolean
  thumbnailImage: string
}

const validate = createValidation<FormValue>(() => ({
  title: cp(required('required title'), maxLength(50, 'Maximum length required 50')),
  description: cp(required('required description'), maxLength(100, 'Maximum length required 100')),
  authorName: cp(required('required author name'), maxLength(50, 'Maximum length required 50')),
}))

const ArticleDetail = () => {
  const history = useRouter()
  const { query } = useRouter()
  const { data: currentUser } = useCurrentUser()
  const articleId = useMemo(() => query.id, [query.id])
  const { data: articleData } = useGetArticleById(articleId)
  const [isViewMode, setIsViewMode] = useState(false)
  const [bodyValue, setBodyValue] = useState<string>('')
  const [isEmptyBody, setIsEmptyBody] = useState<boolean>(true)
  const onConfirmModalVisible = useVisible()
  const onDeleteModalVisible = useVisible()
  const classes = useStyles()

  const handleOpenConfirmModal = useCallback(() => {
    onConfirmModalVisible.open()
  }, [onConfirmModalVisible])

  const handleCloseConfirmModal = useCallback(() => {
    onConfirmModalVisible.close()
  }, [onConfirmModalVisible])

  const handleOpenDeleteModal = useCallback(() => {
    onDeleteModalVisible.open()
  }, [onDeleteModalVisible])

  const handleCloseDeleteModal = useCallback(() => {
    onDeleteModalVisible.close()
  }, [onDeleteModalVisible])

  useEffect(() => {
    if (isEmpty(bodyValue) || bodyValue === '<p><br></p>') {
      setIsEmptyBody(true)
    } else {
      setIsEmptyBody(false)
    }
  }, [bodyValue])

  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['image', 'link'], // Include 'image' in the toolbar options
        // Add other modules as needed
      ],
      blotFormatter: {},

      imageCompress: {
        quality: 0.7, // default 0.7
        maxWidth: 1000, // default 1000
        maxHeight: 1000, // default 1000
        imageType: 'image/jpeg', // default image/jpeg
        debug: true, // default true
        insertIntoEditor: (imageBase64URL: string, imageBlob: Blob, editor: Quill) => {
          const fileSizeInKb = imageBlob.size / 1024
          if (fileSizeInKb <= 150) {
            const range = editor.getSelection()
            editor.insertEmbed(range?.index || 0, 'image', `${imageBase64URL}`, 'user')
          } else {
            toast.error('File to Large')
          }
        },
      },
    }
  }, [])

  useEffect(() => {
    if (articleData?.id || !validateCorrectRole(ROLE.MANAGER, currentUser?.role)) {
      setIsViewMode(true)
    }
  }, [articleData, currentUser])

  const initialValues = useMemo(() => {
    return query.id
      ? {
          title: articleData?.title,
          authorName: articleData?.authorName,
          isActive: articleData?.isActive,
          description: articleData?.description,
          thumbnailImage: articleData?.thumbnailImage,
        }
      : {
          isActive: false,
        }
  }, [articleData, query.id])

  const { mutate: updateArticle } = useEditArticle(articleData?.id || '')
  const { mutate: createArticle } = useCreateArticle()
  const { mutate: deleteArticle } = useDeleteArticle(articleData?.id || '')

  const onGoBackToArticle = useCallback(() => {
    history.push(paths.article())
  }, [history])

  const onSubmit = useCallback(
    values => {
      const { title, authorName, isActive, description, thumbnailImage } = values
      const params: ArticleBodyType = {
        title,
        authorName,
        body: bodyValue,
        isActive,
        description,
        thumbnailImage,
        userId: currentUser?.id || '',
      }

      if (query.id) {
        updateArticle(params, {
          onSuccess: () => {
            Toast.info('Success')
            handleCloseConfirmModal()
            onGoBackToArticle()
          },
          onError: error => {
            Toast.info('ไฟล์รูปภายในบทความอาจมีความใหญ่เกินไป')
            handleCloseConfirmModal()
          },
        })
      } else {
        createArticle(params, {
          onSuccess: error => {
            Toast.info('Success')
            handleCloseConfirmModal()
            onGoBackToArticle()
          },
          onError: error => {
            Toast.info('ไฟล์รูปภายในบทความอาจมีความใหญ่เกินไป')
            handleCloseConfirmModal()
          },
        })
      }
    },
    [
      bodyValue,
      createArticle,
      currentUser,
      handleCloseConfirmModal,
      onGoBackToArticle,
      query.id,
      updateArticle,
    ],
  )

  useEffect(() => {
    if (isViewMode) {
      setBodyValue(articleData?.body || '')
    }
  }, [articleData, isViewMode])

  const onClickEdit = useCallback(() => {
    setIsViewMode(false)
  }, [])

  const onClickDelete = useCallback(() => {
    deleteArticle()
    onGoBackToArticle()
  }, [deleteArticle, onGoBackToArticle])

  return (
    <>
      <Authorize role={ROLE.MANAGER}>
        {query.id && (
          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ButtonStyled
              variant="warning"
              onClick={onClickEdit}
              style={{
                marginRight: '16px',
              }}
            >
              Edit
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleOpenDeleteModal}>
              Delete
            </ButtonStyled>
          </div>
        )}
      </Authorize>
      <CustomCard>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, invalid, values, form }) => {
            return (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                    <ContentLayout>
                      <div style={{ paddingBottom: '10px' }}>
                        <Text>Title</Text>
                        <Field
                          name="title"
                          component={InputField}
                          placeholder="Title"
                          id="title"
                          disabled={isViewMode}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ paddingBottom: '10px' }}>
                        <Text>Description</Text>
                        <Field
                          name="description"
                          component={InputField}
                          placeholder="Description"
                          id="description"
                          disabled={isViewMode}
                          style={{ width: '100%' }}
                          multiline
                          rowsMax="4"
                        />
                      </div>
                      <div style={{ paddingBottom: '10px' }}>
                        <Text>Author Name</Text>
                        <Field
                          name="authorName"
                          component={InputField}
                          id="authorName"
                          placeholder="Author Name"
                          disabled={isViewMode}
                          style={{ width: '100%' }}
                        />
                      </div>
                      {!isViewMode && (
                        <div style={{ paddingBottom: '10px', cursor: 'pointer' }}>
                          <Text>Cover Image</Text>
                          {!values.thumbnailImage ? (
                            <UploadThumbNail
                              onChange={value => {
                                form?.change('thumbnailImage', value)
                              }}
                            />
                          ) : (
                            <GridUploadArea container alignItems="center" justify="space-between">
                              <div>
                                <AttachFileIcon
                                  width={40}
                                  style={{ width: '24px', height: '24px' }}
                                />
                                Uploaded
                              </div>
                              <DeleteIcon
                                style={{ color: 'red', width: '24px', height: '24px' }}
                                onClick={() => {
                                  form?.change('thumbnailImage', null)
                                }}
                              />
                            </GridUploadArea>
                          )}
                        </div>
                      )}
                      <div style={{ margin: '16px 0', display: 'flex', justifyContent: 'center' }}>
                        {!!values?.thumbnailImage && (
                          <Card style={{ padding: 0 }}>
                            <CardMedia
                              component="img"
                              width="100%"
                              image={values?.thumbnailImage}
                              alt="cover_image"
                              style={{
                                aspectRatio: '16 / 9',
                                //maxWidth: 320,
                              }}
                            />
                            <CardContent style={{ maxWidth: 320, padding: 0 }}>
                              <Text variant="h6">{values?.title}</Text>
                              <Text>
                                {values?.description
                                  ?.split('\n')
                                  .map((line: string, index: number) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </Text>
                            </CardContent>
                          </Card>
                        )}
                      </div>

                      <FormControlLabel
                        control={
                          <Field name="isActive" component={CheckBoxField} disabled={isViewMode} />
                        }
                        label="Active"
                      />
                    </ContentLayout>
                    {!isViewMode && (
                      <ReactQuill
                        theme="snow"
                        value={bodyValue}
                        onChange={setBodyValue}
                        modules={modules}
                        style={{
                          width: '100%',
                          maxWidth: '500px',
                          height: '440px',
                          maxHeight: '440px',
                          padding: '0 24px',
                        }}
                        placeholder="Write your article..."
                      />
                    )}
                    <Authorize role={ROLE.MANAGER}>
                      <MarginTopButton style={{ marginTop: isViewMode ? '24px' : '' }}>
                        <Button
                          onClick={handleOpenConfirmModal}
                          disabled={invalid || isViewMode || isEmptyBody}
                        >
                          Submit
                        </Button>
                      </MarginTopButton>
                    </Authorize>
                  </div>
                  <div style={{ width: '50%' }}>
                    <IPhoneFrame>
                      <Screen className={imgStyle}>
                        <ColumnText
                          className="view ql-editor"
                          dangerouslySetInnerHTML={{
                            __html: bodyValue,
                          }}
                        />
                      </Screen>
                    </IPhoneFrame>
                  </div>
                </div>
                <Modal
                  className={classes.modal}
                  open={onConfirmModalVisible.visible}
                  onClose={onConfirmModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={handleSubmit}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={onConfirmModalVisible.close}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
                <Modal
                  className={classes.modal}
                  open={onDeleteModalVisible.visible}
                  onClose={onDeleteModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm Delete</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={onClickDelete}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={handleCloseDeleteModal}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
              </>
            )
          }}
        />
      </CustomCard>
    </>
  )
}

export default ArticleDetail
