import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import ModalCreateGiveaway from './ModalCreateGiveaway'
import { useGetGiveaways, useUploadGiveaway } from '../../../services/giveaway/giveaway-query'
import { useHistory } from 'react-router-dom'
import { IGiveaway } from '../../../services/giveaway/giveaway-typed'
import ModalExportGiveaway from './ModalExportGiveaway'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import { appConfig } from '../../../constants/app-config'
import { useOtpMobileByToken } from '../../../services/otp/otp-query'
import { OtpAction } from '../../../services/otp/otp-types'
import OTPModal from '../../../components/common/OTPModal'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
  margin-right: 16px;
`

type OTPState = {
  id: string
  refCode: string
}

const RewardList = () => {
  const { push, query } = useRouter()
  const history = useHistory()
  const { q, page } = query

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [otpModalVisible, setOtpModalVisible] = useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [otpState, setOtpState] = useState<OTPState | null>(null)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const { mutateAsync: uploadFile } = useUploadGiveaway()
  const { mutateAsync: requestOtp } = useOtpMobileByToken()
  const { t } = useTranslation()

  const onOpenExportModal = useCallback(() => {
    setOpenExportModal(true)
  }, [])

  const onClickImportFile = useCallback(() => {
    history.push(paths.rewardCreate())
  }, [history])

  const onCloseExportModal = useCallback(() => {
    setOpenExportModal(false)
  }, [])
  const onOpenCreateModal = useCallback(() => {
    setOpenCreateModal(true)
  }, [])

  const onCloseCreateModal = useCallback(() => {
    setOpenCreateModal(false)
  }, [])

  const onClickViewDetail = useCallback(
    (giveawayId: string) => {
      history.push(paths.rewardDetail({ routeParam: { giveawayId } }))
    },
    [history],
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Campaign name',
        dataIndex: 'campaignName',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
      },
      { title: 'Tmn Id', dataIndex: 'user.tmnId' },
      {
        title: 'Amount',
        dataIndex: 'amount',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
      },
      {
        title: 'Create At',
        dataIndex: 'createdAt',
        render: (text: string) => {
          return <Text>{dayjs(text).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text: string) => {
          //const { isActive } = record
          return <Text>{text}</Text>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, giveaway: IGiveaway) => {
          console.debug({ giveaway })
          return <Button onClick={onClickViewDetail.bind(null, giveaway.id)}>View</Button>
        },
      },
    ]
  }, [onClickViewDetail])

  const { data: giveaways } = useGetGiveaways({ q, page })

  const setQueryParam = useCallback(
    params => {
      push(paths.rewards({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  const toast = useToastForReactQuery()

  // const onDrop = useCallback(
  //   async (acceptedFiles: File[]) => {
  //     if (acceptedFiles.length > 0) {
  //       const file = acceptedFiles[0]

  //       uploadFile(file, {
  //         onError: error => {
  //           //console.debug({ error })
  //           toast.open(error, {
  //             type: 'error',
  //             autoClose: 3 * 1000,
  //           })
  //         },
  //         onSuccess: () => {
  //           toast.open('Success', {
  //             type: 'success',
  //             autoClose: 3 * 1000,
  //           })
  //         },
  //       })
  //     }
  //   },
  //   [toast, uploadFile],
  // )
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]

        try {
          const otpResponse = await requestOtp({
            action: OtpAction.MANUAL_GIVE,
          })
          setOtpState({ id: otpResponse.otpId, refCode: otpResponse.refCode })
          setFileToUpload(file)
          setOtpModalVisible(true)
        } catch (error) {
          toast.open(t(error), {
            type: 'error',
            autoClose: 3 * 1000,
          })
        }
      }
    },
    [requestOtp, t, toast],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    multiple: false,
  })

  const handleOtpConfirm = useCallback(
    (otp: string) => {
      const body = { file: fileToUpload, otpData: { otp, otpId: otpState?.id } }
      if (otpState && fileToUpload) {
        uploadFile(body, {
          onError: error => {
            if (error === '422-022') {
              setOtpErrorMessage(t(error))
            } else {
              toast.open('Can not create give away', {
                type: 'error',
                autoClose: 3 * 1000,
              })
              setOtpModalVisible(false)
            }
          },
          onSuccess: () => {
            toast.open('Success', {
              type: 'success',
              autoClose: 3 * 1000,
            })
            setOtpModalVisible(false)
          },
        })
      }
    },
    [fileToUpload, otpState, uploadFile, otpModalVisible, t, toast],
  )

  return (
    <>
      <ListPage
        topbar={
          appConfig.ENABLE_FEATURE_GIVEAWAY ? (
            <Authorize role={ROLE.SUPER_ADMIN}>
              <Layout>
                <ButtonStyled
                  {...getRootProps({ className: 'dropzone' })}
                  variant="outline-primary"
                  // onClick={onOpenOTPModal}
                >
                  import file
                  <input {...getInputProps()} />
                </ButtonStyled>
                <ButtonStyled variant="outline-warning" onClick={onOpenExportModal}>
                  Export
                </ButtonStyled>

                <ButtonStyled variant="primary" onClick={onOpenCreateModal}>
                  Create
                </ButtonStyled>

                <ModalCreateGiveaway visible={openCreateModal} onClose={onCloseCreateModal} />
                <ModalExportGiveaway visible={openExportModal} onClose={onCloseExportModal} />
              </Layout>
            </Authorize>
          ) : (
            undefined
          )
        }
        tableProps={{
          columns,
          data: giveaways?.items || [],
          onPageChange,
          pagination: giveaways?.meta,
        }}
        searchInputProps={{ onSearch, value: query.q, placeholder: 'Search' }}
      />
      <OTPModal
        show={otpModalVisible}
        handleClose={() => setOtpModalVisible(false)}
        handleConfirm={handleOtpConfirm}
        otpRef={otpState?.refCode}
        errorMessage={otpErrorMessage}
      />
    </>
  )
}

export default RewardList
