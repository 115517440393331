import React, { Fragment, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Card } from 'react-bootstrap'
import Text from '../../components/common/Text'
import Image from 'react-bootstrap/Image'
import { ROLE, User } from '../../services/user/user-typed'
import dayjs from 'dayjs'
import { useGetImageUser, useCurrentUser } from '../../services/user/user-query'
import profile from '../../../asset/images/default_profile_picture.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { useVisible } from '../../../utils/custom-hooks'
import ModalUpdateUserProfile from './ModalUpdateUserProfile'
import EditIcon from '@material-ui/icons/Edit'
import ModalChangeTmnProfile from './ModalChangeTmnProfile'
import { appConfig } from '../../constants/app-config'
import { Authorize } from '../Authorize'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const UserDetailLayout = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
`

const EditIconLayout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-start;
  grid-row-gap: 12px;

  .edit-profile-desc {
    max-width: 100px;
  }
`

const EditIconStyled = styled(EditIcon)<{ disabled?: boolean }>`
  &.MuiSvgIcon-root {
    width: 28px;
    height: 28px;
    margin-top: 20px;
    color: ${({ disabled }) => (disabled ? '#3F4254' : '#17c191')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`

const EditTmnIconStyled = styled(EditIcon)<{ disabled?: boolean }>`
  &.MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    /* margin-top: 20px; */
    margin-left: 32px;
    color: ${({ disabled }) => (disabled ? '#3F4254' : '#17c191')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`

const RootLayout = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
`
const ContentDetailLayout = styled(Grid)`
  grid-template-columns: 150px auto;
`

export const Flex = styled(Grid)`
  display: flex;
  margin: 0px;
`
const Margin = styled(Grid)`
  margin: 20px;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

type DetailPage = {
  user: User
  gridItems?: JSX.Element[]
  role?: string
}

export const UserProfileCard = (props: DetailPage) => {
  const { user, gridItems } = props
  const classes = useStyles()
  const {
    firstnameEn,
    lastnameEn,
    firstnameTh,
    lastnameTh,
    citizenId,
    gender,
    mobileNo,
    email,
    job,
    birthdate,
    callbackConvenientTime,
    callbackMobileNo,
    imageProfile,
    typeSignIn,
    tmnProfile,
    tmnId,
    id: userId,
  } = user

  const { id: imageUserProfileId = '' } = imageProfile || {}
  const { data: imageUSerProfile } = useGetImageUser(imageUserProfileId, 'origin')
  const { image } = imageUSerProfile || { image: 'noImage' }

  const changeMobileNoVisible = useVisible()

  //const changeTmnModal = useVisible()
  const [changeTmnModal, setChangeTmnModal] = useState(false)
  const onOpenChangeTmnModal = useCallback(() => {
    setChangeTmnModal(true)
  }, [])

  const onCloseChangeTmnModal = useCallback(() => {
    setChangeTmnModal(false)
  }, [])

  return (
    <RootLayout>
      <Card style={{ gridColumn: '1 / -1' }}>
        <Card.Body>
          <div>
            <Flex>
              {image === 'noImage' ? (
                <Image src={profile} rounded width="250px" height="250px" />
              ) : (
                <Image src={image} rounded width="250px" height="250px" />
              )}
              <UserDetailLayout>
                <Margin>
                  <Text variant="h5" bold>
                    {firstnameEn ? firstnameEn : '-'} {lastnameEn}
                  </Text>

                  <Text variant="h5" bold>
                    {firstnameTh ? firstnameTh : '-'} {lastnameTh}
                  </Text>

                  <ContentDetailLayout>
                    <Text>Citizend Id</Text>
                    <Text bold>{citizenId ? citizenId : '-'}</Text>
                    <Text>Gender</Text>
                    <Text bold>{gender ? gender : '-'}</Text>
                    <Text>Mobile No</Text>
                    <Text bold>{mobileNo ? mobileNo : '-'}</Text>
                    <Text>Email</Text>
                    <Text bold>{email ? email : '-'}</Text>
                    <Text>Type SignIn</Text>
                    <Text bold>{typeSignIn ? typeSignIn.toLocaleUpperCase() : '-'}</Text>
                    <Text>Tmn Id</Text>
                    {/* {appConfig.ENABLE_FEATURE_TRUE_MONEY ? (
                      <div style={{ display: 'flex' }}>
                        <Text bold>{tmnId ? tmnId : '-'}</Text>
                        <Authorize role={ROLE.ADMIN}>
                          <EditTmnIconStyled onClick={onOpenChangeTmnModal} />
                        </Authorize>
                      </div>
                    ) : (
                      <Text bold>-</Text>
                    )} */}
                  </ContentDetailLayout>
                </Margin>
                <Authorize role={ROLE.ADMIN}>
                  <EditIconLayout>
                    <EditIconStyled onClick={changeMobileNoVisible.open} />
                  </EditIconLayout>
                </Authorize>
              </UserDetailLayout>
            </Flex>
          </div>

          <ContentDetailLayout>
            <Text>Job</Text>
            <Text bold>{job ? job : '-'}</Text>
            <Text>Birthdate</Text>
            <Text bold>{birthdate ? dayjs(birthdate).format('DD MMM YYYY') : '-'}</Text>
            <Text>Convenient Time</Text>
            <Text bold>{callbackConvenientTime ? callbackConvenientTime : '-'}</Text>
            <Text>Callback Number</Text>
            <Text bold>{callbackMobileNo ? callbackMobileNo : '-'}</Text>
            <Text style={{ alignSelf: 'center' }}>Role</Text>
            <Text bold>{user.role ? user.role.toLocaleUpperCase() : '-'}</Text>
          </ContentDetailLayout>
        </Card.Body>
      </Card>
      {gridItems?.map((gridItem, index) => (
        <Fragment key={`${index}`}>{gridItem}</Fragment>
      ))}
      <ModalUpdateUserProfile
        userId={userId}
        visible={changeMobileNoVisible.visible}
        onClose={changeMobileNoVisible.close}
        className={classes.modal}
      />
      <ModalChangeTmnProfile
        userId={userId}
        visible={changeTmnModal}
        onClose={onCloseChangeTmnModal}
        //className={classes.modal}
      />
    </RootLayout>
  )
}
export default UserProfileCard
