import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { useGetGiveaways, useUploadGiveaway } from '../../../services/giveaway/giveaway-query'
import { useHistory } from 'react-router-dom'
import { IGiveaway } from '../../../services/giveaway/giveaway-typed'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import { appConfig } from '../../../constants/app-config'
import ModalCreateGiveaway from '../RewardList/ModalCreateGiveaway'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
  margin-right: 16px;
`

const RewardList = () => {
  const { push, query } = useRouter()
  const history = useHistory()
  const { q, page } = query

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [, setOpenExportModal] = useState(false)

  const onOpenExportModal = useCallback(() => {
    setOpenExportModal(true)
  }, [])

  const onClickImportFile = useCallback(() => {
    history.push(paths.rewardCreate())
  }, [history])

  const onOpenCreateModal = useCallback(() => {
    setOpenCreateModal(true)
  }, [])

  const onCloseCreateModal = useCallback(() => {
    setOpenCreateModal(false)
  }, [])

  const onClickViewDetail = useCallback(
    (giveawayId: string) => {
      history.push(paths.rewardDetail({ routeParam: { giveawayId } }))
    },
    [history],
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Campaign name',
        dataIndex: 'campaignName',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
      },
      { title: 'Tmn Id', dataIndex: 'user.tmnId' },
      {
        title: 'Amount',
        dataIndex: 'amount',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
      },
      {
        title: 'Create At',
        dataIndex: 'createdAt',
        render: (text: string) => {
          return <Text>{dayjs(text).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text: string) => {
          //const { isActive } = record
          return <Text>{text}</Text>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, giveaway: IGiveaway) => {
          console.debug({ giveaway })
          return <Button onClick={onClickViewDetail.bind(null, giveaway.id)}>View</Button>
        },
      },
    ]
  }, [onClickViewDetail])

  const { data: giveaways } = useGetGiveaways({ q, page })

  const setQueryParam = useCallback(
    params => {
      push(paths.rewards({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  return (
    <ListPage
      topbar={
        appConfig.ENABLE_FEATURE_GIVEAWAY ? (
          <Authorize role={ROLE.SUPER_ADMIN}>
            <Layout>
              <ButtonStyled variant="outline-primary" onClick={onClickImportFile}>
                import file
              </ButtonStyled>
              <ButtonStyled variant="outline-warning" onClick={onOpenExportModal}>
                Export
              </ButtonStyled>

              <ButtonStyled variant="primary" onClick={onOpenCreateModal}>
                Create
              </ButtonStyled>

              <ModalCreateGiveaway visible={openCreateModal} onClose={onCloseCreateModal} />
            </Layout>
          </Authorize>
        ) : (
          undefined
        )
      }
      tableProps={{
        columns,
        data: giveaways?.items || [],
        onPageChange,
        pagination: giveaways?.meta,
      }}
      searchInputProps={{ onSearch, value: query.q, placeholder: 'Search' }}
    />
  )
}

export default RewardList
