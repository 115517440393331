import { get } from 'lodash'
import React from 'react'
import { appConfig } from './constants/app-config'
import { Helmet } from 'react-helmet'

interface IAppMeta {
  title: string
  mediaDir: string
}
enum EnumAppName {
  SAVVY_GOLD = 'savvy-gold',
  SAVVY_TMN = 'savvy-tmn',
  MY_GOLD_PLUS = 'my-gold-plus',
}

const appMetaConfig: Record<string, IAppMeta> = {
  [EnumAppName.SAVVY_GOLD]: {
    title: 'Savvy Gold Admin',
    mediaDir: 'savvy-gold',
  },
  [EnumAppName.SAVVY_TMN]: {
    title: 'Safegold Admin',
    mediaDir: 'savvy-tmn',
  },
  [EnumAppName.MY_GOLD_PLUS]: {
    title: 'MyGOLD Plus Admin',
    mediaDir: 'my-gold-plus',
  },
}

export const appMeta = get<Record<string, IAppMeta>, string, IAppMeta>(
  appMetaConfig,
  appConfig.APP_NAME,
  appMetaConfig[EnumAppName.MY_GOLD_PLUS],
)

export const AppHelmet = () => {
  return (
    <Helmet>
      <title>{appMeta.title}</title>
      <link rel="icon" type="image/x-icon" href={`/media/app/${appMeta.mediaDir}/favicon.ico`} />
    </Helmet>
  )
}
